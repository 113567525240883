import React from 'react';
import { Link, browserHistory } from 'react-router';
import { Menu, List, Label, Popup, Image } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { inject, observer } from 'mobx-react';

import { __ } from '../../i18n';
import Avatar from '../../components/Avatar';
import { entityTypes } from './helpers/getEntityTypes';

const EntityList = ({ entities, header, mobile }) => (
  <List style={{ width: mobile ? '80vw' : '400px', border: 'solid 1px #E8E8E8', padding: '10px', borderRadius: '20px' }} selection verticalAlign="middle">
    {header}
    {entities.map(entity => (
      <List.Item
        style={{ margin: '8px 0px', display: 'flex', flexDirection: 'row' }}
        key={entity.id} as={Link}
        to={`/entities/${entity.id}/messages`}
      >
        <div style={{ display: 'inline-block', marginRight: '5px' }}>
          <Avatar avatar src={entity.picture && entity.picture.uri} alt={entity.fullname} />
        </div>
        <List.Content style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <List.Header>
            {entity.fullname.length > 18 ?
              <Popup
                trigger={<span style={{ fontSize: '15px', fontWeight: 'normal' }}>{entity.fullname.substring(0, 18).trim()}….</span>}
                content={entity.fullname}
              />
              :
              <span style={{ fontSize: '16px', fontWeight: 'normal' }}>{entity.fullname}</span>}
          </List.Header>
          <Label
            basic
            style={{ fontSize: '.9em', marginLeft: '5px', marginRight: 'auto', padding: '4px', color: '#6C6C6C' }}
            content={entityTypes(entity.organization.type)[entity.type].name}
          />
          {(entity.hasUnreadCommunication || entity.hasUnreadMoment) && (
            <Label
              size="tiny"
              circular
              className="inverted"
              style={{ backgroundColor: '#C80000' }}
            />
          )}
        </List.Content>
      </List.Item>
    ))}
  </List >
);

@inject('store') @observer
export default class OrganizationItem extends React.Component {
  static fragments = {
    organization: gql`
      fragment OrganizationItem on Organization {
        id: dbId
        username
        fullname
        plan
        premiumPeriod
        isDemonstration
        monthlyMessagesCount
        monthlyMessageQuota
        created
      }`,
    vertical: gql`
        fragment OrganizationItemVertical on Organization {
          id: dbId
          fullname
          plan
          premiumPeriod
          isDemonstration
          monthlyMessagesCount
          monthlyMessageQuota
          logo {
            id: dbId
            uri(size: "w360")
          }
          entities: myEntities (limit: 200) {
            totalCount
            nodes {
              id: dbId
              fullname
              type
              status
              picture {
                uri
                id: dbId
                key
              }
              organization {
                id: dbId
                type
              }
              hasUnreadCommunication
              hasUnreadMoment
            }
          }
        }`
  }

  render() {
    const { organization, onClick, isAdmin, to, vertical, first, mobile } = this.props;
    const className = first ? 'first' : '';

    const params = {};
    if (to && !vertical) {
      params.as = Link;
      params.to = to;
    } else if (to && vertical && isAdmin) {
      params.onClick = () => browserHistory.push(to);
      params.className = 'pointer';
    }

    if (vertical) {
      const entities = organization.entities.nodes;
      return (
        <Menu.Item
          className={'organizationItem vertical ' + className}
          data-id={organization.id}
        >
          <EntityList
            mobile={mobile}
            header={
              <List.Item style={{ marginBottom: '15px' }} {...params}>
                <List.Content>
                  <List.Header title={organization.fullname}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ marginRight: '5px', display: 'inline-block' }}>
                        <Avatar
                          avatar
                          style={{ width: '35px', height: '35px' }}
                          src={organization.logo && organization.logo.uri} alt={organization.fullname}
                        />
                      </div>
                      {organization.fullname.length > 18 ?
                        <Popup
                          trigger={<span>{organization.fullname.substring(0, 18).trim()}….</span>}
                          content={organization.fullname}
                        />
                        :
                        <span style={{ fontWeight: 'bold', fontSize: '17px' }}>{organization.fullname}</span>}
                    </div>
                  </List.Header>
                </List.Content>
              </List.Item>}
            entities={entities}
          />
        </Menu.Item>
      );
    }

    return (
      <Menu.Item
        className={'organizationItem ' + className}
        data-id={organization.id}
        onClick={onClick ? () => onClick(organization) : null}
        {...params}
        content={organization.fullname}
        icon={isAdmin ? 'cog' : null}
      >
        {this.props.children}
      </Menu.Item>
    );
  }
}
